<template>
  <div v-if="state === states.LOADING">
    <Loader v-if="isProcessing" />
  </div>
  <div v-else-if="state === states.ERROR">
    <h1 class="text-2xl md:text-3xl xl:text-4xl">
      Sorry, something’s gone wrong
    </h1>
    <p v-if="errorMessage">
      {{ errorMessage }}
    </p>
    <p>
      Try signing up <router-link
        :to="{ name: 'sign-up' }"
        class="text-base cta-link font-medium"
      >
        signing up
      </router-link>
      again
    </p>
  </div>
</template>

<script>
import Loader from '@components/Loader'
import { bingSignUpEvent } from '@utils/bingUet'

const states = {
  LOADING: 'loading',
  ERROR: 'error'
}

export default {
  components: {
    Loader
  },

  data() {
    return {
      states,
      state: states.LOADING,
      errorMessage: null,

      isProcessing: true
    }
  },

  computed: {
    /**
     * @return {string}
     */
    validationCode() {
      return this.$route.query.code
    }
  },

  created() {
    this.signIn()
  },

  methods: {
    /**
     * Validate the code
     */
    signIn() {
      if (!this.validationCode) {
        this.errorMessage = 'The code is missing from the link, please try again'
        this.state = this.states.ERROR
        return
      }

      this.$store.dispatch('employers/validateEmail', {
        validationCode: this.validationCode
      })
        .then(() => {
          if (this.$gtm) {
            this.$gtm.trackEvent({
              event: 'event',
              eventCategory: 'Employer',
              eventAction: 'Sign up',
              eventLabel: 'Via email'
            })
          }

          // Google Adwords
          if (this.$gtm) {
            this.$gtm.trackEvent({
              event: 'Google Ads Registration Tracking'
            })
          }

          bingSignUpEvent('Employer')

          if (window.fbq) {
            window.fbq('trackCustom', 'Sign up')
          }

          this.$router.push({ name: 'client-assessments-list' })
        })
        .catch(error => {
          this.errorMessage = 'The was an error processing your validation code'
          this.state = this.states.ERROR
          throw error
        })
    }
  }
}
</script>
